import { useState, useEffect } from "react";
import Datetime from "react-datetime";

const TimePicker = (props) => {

    const [value, setValue] = useState(new Date())

    useEffect(() => {
        setValue(props.value)
    }, [props.value])

    const onChangeDate = (event) => {
        setValue(event)
        if (props.onChange) props.onChange(event)
    }

    return (
        <fieldset className="p-1" style={{ height: 40 }}>
            <legend>{props.label}</legend>
            <div className="d-flex">
                <Datetime
                    dateFormat={false}
                    timeFormat={props.timeFormat || "HH:mm"}
                    value={value}
                    onChange={onChangeDate}
                    className="flex-1"
                />
                <i className="fa fa-clock-o mt-2 text-secondary"></i>
            </div>
        </fieldset>
    )
}

export default TimePicker