
const BETA_URL = "https://beta.mylocatorplus.com";
const DEV_URL = "https://dev.mylocatorplus.com";
const ALPHA_URL = "https://alpha.mylocatorplus.com";
const LOCAL = "http://localhost:9090";

const __init__ = () => {

  if (window.location.origin.match('beta.mylocatorplus.com')) {
    return BETA_URL
  }

  if (window.location.origin.match('dev.mylocatorplus.com')) {
    return DEV_URL
  }

  if (window.location.origin.match('alpha.mylocatorplus.com')) {
    return ALPHA_URL
  }

  if (window.location.origin.match('localhost:')) {
    // return LOCAL
    return BETA_URL
    //  return DEV_URL
  }

  return BETA_URL



}

export const MAIN_DOMAIN_URL = __init__();
export const MAIN_DOMAIN_IP_URL = "http://37.61.213.78:9090/";
export const WEB_SOCKETS_URL = `wss://alpha.mylocatorplus.com/gps/api/socket`;
export const VSS_IFRAME_URL = 'https://alpha.mylocatorplus.com/vss/apiPage'

// export const LOCAL_BACKEND = "http://localhost:9090";
export const LOCAL_BACKEND = "http://localhost:9090";

const getTaskManageAPIURL = () => MAIN_DOMAIN_URL;
// const getTaskManageAPIURL = () =>
//   window.location.href.match("localhost") !== null
//     ? LOCAL_BACKEND
//     : MAIN_DOMAIN_URL;

export const API_URLS = {
  API_POST_URL: `${MAIN_DOMAIN_URL}/gateway/index.php/api-v1`,
  API_GET_URL: `${MAIN_DOMAIN_URL}/gateway/index.php/api-v1`,
  API_GET_URL_PLUS: `${MAIN_DOMAIN_URL}/gateway/index.php/api-v1`,
  API_REPORT_URL: `${MAIN_DOMAIN_URL}/gateway/index.php`,
  ASSETS_URL: `${MAIN_DOMAIN_IP_URL}`,
  BASE_URL: `${MAIN_DOMAIN_IP_URL}`,
  ROUTE_OPTIMIZER_API: `${MAIN_DOMAIN_URL}/optimization-plannner/`,
  APP_URL: `${MAIN_DOMAIN_URL}`,

  TASK_MANAGER_URL: `${getTaskManageAPIURL()}/task-manager/api/v1`,
  SERVICE_CENTER_URL: `${getTaskManageAPIURL()}/task-manager/api/v1`,
  ASSETS_BACKEND_URL: `${getTaskManageAPIURL()}/task-manager/assets`,

  LOCATOR_V2_API_URL: `${MAIN_DOMAIN_URL}/locator-main/api/v1`,
  TRACCAR_API: `${MAIN_DOMAIN_URL}/gps/api`,
  IOT_API: "",
};