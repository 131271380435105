import React from 'react';
import { useSelector } from 'react-redux';


import './MapContentView.css';



const MapContentView = (props) => {

    const showSidebar = useSelector(state => state.groupReducer.showSidebar);
    const isReportMap = useSelector(state => state.reportReducer.isShowMap)

    var cn = showSidebar ? "as-content-view-map" : "as-content-view-map as-w-99-8";

    cn = isReportMap ? cn + ' move-to-left' : cn;

    return (
        <div className={cn} >
          <div className="as-content-map-view" style={props.style}>
            {props.children}
            </div>
        </div>
    );
}

export default MapContentView;