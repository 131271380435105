import React from "react";
import Modal from "react-bootstrap/Modal";
import { Container } from "react-bootstrap";
import { useState } from "react";
// import TimePicker from "../UI/TimePicker";
import TaskManagerRequest from "../../services/TaskManagerRequest";
import { ShowNotification } from "../../services/Notifications";
import moment from "moment";
import { useEffect } from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";

const OfficeTimeSettingsModal = (props) => {
  const [fromTime, setFromTime] = useState(Date.now());
  const [toTime, setToTime] = useState(Date.now());

  const updateOfficeTime = async (data) => {
    try {
      const res = await TaskManagerRequest(
        "/add/settings",
        data,
        false,
        "officeTimeSave"
      );
      props.toggleModal();
    } catch (error) {
      ShowNotification("error", error);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    var data = {
      attendanceSettings: {
        officeTime: {
          officeStartTime: moment(fromTime).format("HH:mm"),
          officeEndTime: moment(toTime).format("HH:mm"),
        },
      },
    };

    updateOfficeTime(data);
  };

  const deleteOfficeTime = () => {
    var data = {
      attendanceSettings: {
        officeTime: {
          officeStartTime: "",
          officeEndTime: "",
        },
      },
    };

    updateOfficeTime(data);
  };

  const getDefaultTime = () => {
    var _date = new Date();
    _date.setHours(0);
    _date.setMinutes(0);
    return _date;
  };

  const loadData = async () => {
    try {
      const res = await TaskManagerRequest("/get/setting");

      if (
        res &&
        res.attendanceSettings &&
        res.attendanceSettings.officeTime &&
        res.attendanceSettings.officeTime.officeStartTime !== ""
      ) {
        var d = new Date();

        d.setHours(
          moment(
            res.attendanceSettings.officeTime.officeStartTime,
            "HH:mm"
          ).format("HH")
        );
        d.setMinutes(
          moment(
            res.attendanceSettings.officeTime.officeStartTime,
            "HH:mm"
          ).format("mm")
        );
        setFromTime(d);
      } else {
        setFromTime(getDefaultTime());
      }

      if (
        res &&
        res.attendanceSettings &&
        res.attendanceSettings.officeTime &&
        res.attendanceSettings.officeTime.officeEndTime !== ""
      ) {
        var _d = new Date();

        _d.setHours(
          moment(
            res.attendanceSettings.officeTime.officeEndTime,
            "HH:mm"
          ).format("HH")
        );
        _d.setMinutes(
          moment(
            res.attendanceSettings.officeTime.officeEndTime,
            "HH:mm"
          ).format("mm")
        );
        setToTime(_d);
      } else {
        setToTime(getDefaultTime());
      }
    } catch (error) {
      setFromTime(getDefaultTime());
      setToTime(getDefaultTime());
    }
  };

  useEffect(() => {
    if (props.show) {
      loadData();
    }
  }, [props.show]);

  return (
    <>
      <Modal
        show={props.show}
        dialogClassName="modal-40w"
        onHide={props.toggleModal}
        aria-labelledby="attendanceMap"
      >
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton>Office Time Settings</Modal.Header>

          <Modal.Body className="show-grid  mui-date-picker">
            <Container>
              <div className="d-flex">
                <div
                  className="flex-1"
                  style={{
                    border: "1px solid #acacac",
                    borderRadius: 5,
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <TimePicker
                      value={moment(fromTime)}
                      onChange={(d) => {
                        setFromTime(d.toDate());
                      }}
                      sx={{
                        width: "100%",
                      }}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                    />
                  </LocalizationProvider>
                </div>

                <div style={{ width: 20 }}></div>

                <div
                  className="flex-1"
                  style={{
                    border: "1px solid #acacac",
                    borderRadius: 5,
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <TimePicker
                      value={moment(toTime)}
                      onChange={(d) => {
                        setToTime(d.toDate());
                      }}
                      sx={{
                        width: "100%",
                      }}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                    />
                  </LocalizationProvider>
                </div>
              </div>

              {/* <div className="row">

                                <div className="col-md-5">
                                    <TimePicker
                                        value={fromTime}
                                        label="From Time"
                                        timeFormat="hh:mm A"
                                        onChange={(date) => setFromTime(date)}
                                    />
                                </div>

                                <div className="col-md-5">
                                    <TimePicker
                                        value={toTime}
                                        label="To Time"
                                        timeFormat="hh:mm A"
                                        onChange={(date) => setToTime(date)}
                                    />
                                </div>

                                <div className="col-md-2 mt-auto">
                                    <button type='button' onClick={deleteOfficeTime} className="dash-settings-remove mb-0 pointer" style={{ height: 40, width: 40 }}>
                                        <img src={require('../../assets/img/settings/cancel.svg').default} />
                                    </button>
                                </div>

                            </div> */}
            </Container>
          </Modal.Body>

          <Modal.Footer>
            <button
              id="officeTimeSave"
              style={{
                fontSize: "12px",
                backgroundColor: "#007bff",
                color: "#ffffff",
              }}
              className="btn btn-small"
            >
              Save
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default OfficeTimeSettingsModal;
